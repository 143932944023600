import React from "react";
import "./footer.css";
import GooglePlay from "./Get-it-on-google-play-badge.png";

const Footer = () => {
  return (
    <>
      <footer className="container-xl" id="footer">
        <div className="row justify-content-between">
          <div className="col-sm-6 col-lg-4 footer-left">
            <h2 className="mb-5">Come Shop Offline</h2>
            <div className="d-flex">
              <div className="box-1">
                <p className="connaught-place footer-box-text">
                  Connaught Place
                </p>
              </div>
              <div className="box-2">
                <p className="patel-nagar footer-box-text">Patel Nagar</p>
              </div>
              <div className="box-3">
                <p className="rajpath-nagar footer-box-text">Rajpath Area</p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-5 footer-center d-flex justify-content-around">
            <div className="check-out">
              <h2 className="mb-5">Check out</h2>
              <p className="fw-bold">Blogs</p>
              <p className="fw-bold">All Medications</p>
              <p className="fw-bold">Special Offers</p>
            </div>
            <div className="blogs">
              <h2 className="mb-5">Blogs</h2>
              <p className="fw-bold">Facebook</p>
              <p className="fw-bold">Instagram </p>
              <p className="fw-bold">Twitter</p>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3 footer-right d-flex flex-column">
            <h2 className="mb-5">Shop on App</h2>
            <img
              alt=""
              className={"appStore-footer"}
              src="https://static.overlay-tech.com/assets/11d0deba-da5f-4a2d-a1e6-9769f6a9b427.png"
            />
            <img alt="" className={"appStore-footer"} src={GooglePlay} />
          </div>
        </div>
      </footer>
      <footer>
        <div
          className="text-center"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p
            className={"termsAndConditions-footer text-center"}
            style={{ cursor: "pointer" }}
          >
            Terms and Conditions
          </p>
          <p
            className={"privacyPolicy text-center"}
            style={{ cursor: "pointer" }}
          >
            Privacy Policy
          </p>
        </div>
        <div className="text-center">
          <p
            className={"copyright2022SugamAushadhiPvtLtd"}
            style={{ cursor: "pointer" }}
          >
            Copyright{" "}
            <strong className={"copyright2022SugamAushadhiPvtLtdEmphasis1"}>
              Ⓒ
            </strong>{" "}
            2022{" "}
            <strong className={"copyright2022SugamAushadhiPvtLtdEmphasis3"}>
              Sugam Aushadhi
            </strong>{" "}
            Pvt Ltd. All rights reserved
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
