import React from "react";
import Modal from "react-modal";
import "./callAction.css";
import CallActionImg from "./CallAction.svg";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function CallAction({ callActionDisplay, closeCallAction }) {
  const customStyles = {
    content: {
      top: "20%",
      backgroundColor: "rgba(255, 255, 255, 1)",
      borderRadius: 10,
    },
  };

  const notify = () =>
    toast("Good ! Our Doctor will call in few seconds.", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  return (
    <Modal
      style={customStyles}
      className="card col-10 col-md-8 col-lg-6 m-auto"
      isOpen={callActionDisplay}
      ariaHideApp={false}
      onRequestClose={closeCallAction}
      shouldFocusAfterRender={true}
    >
      <div className="upload-container card">
        <div className="card-heading d-flex">
          <img src={CallActionImg} alt="" />
          <h4 className="ms-2">Call the pharmastic</h4>
        </div>
        <div className="card-body callaction-body">
          <span className="country-code">+91</span>
          <input
            type="tel"
            className="input-call"
            maxLength="10"
            minLength="10"
          />
          <button className="confirm-number-btn" onClick={notify}>
            Confirm Number
          </button>
          <ToastContainer />
        </div>
      </div>
    </Modal>
  );
}
