import React, { useState } from "react";
import Modal from "react-modal";
import "./upload.css";
import Dropzone, { useDropzone } from "react-dropzone";
import UploadSuccess from "./UploadSuccess";

function UploadModal({
  modalIsOpen,
  setOpen,
  closeModal,
  handleUploadSuccess,
  setModalOpen,
}) {
  const customStyles = {
    content: {
      // top: "50%",
      // left: "50%",
      // right: "auto",
      // bottom: "auto",
      // marginRight: "-50%",
      // transform: "translate(-50%, -50%)",
      top: "20%",
      backgroundColor: "rgba(255, 255, 255, 1)",
      borderRadius: 10,
      // padding: " 60px 64.24px 71px 70.24px",
      // display: " flex",
      // flexDirection: "column",
      // alignItems: "flex-start",
      // height: "10vh",
    },
  };

  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    onDropAccepted: handleUploadSuccess,
  });

  const images = files;
  return (
    <Modal
      style={customStyles}
      className="card col-10 col-md-8 col-lg-6 m-auto"
      isOpen={modalIsOpen}
      ariaHideApp={false}
      onRequestClose={closeModal}
      shouldFocusAfterRender={true}
    >
      {files.length === 1 ? (
        <UploadSuccess
          images={images}
          files={files}
          setModalOpen={setModalOpen}
        />
      ) : (
        <div className="upload-container">
          <div className="card-title d-flex ms-4 my-4">
            <img
              alt=""
              className="img-fluid"
              src="https://static.overlay-tech.com/assets/cd7031b1-7ff9-4aa7-bacb-145c2ead5a5d.png"
            />
            <h4 className="ms-2">Upload Prescription</h4>
          </div>
          {/* <Dropzone onDrop={(acceptedFiles) => console.log(acceptedFiles)}>
          {({ getRootProps, getInputProps }) => ( */}
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="card-body text-center flexWrapperOne-modal">
                <h4 className="mb-3">Drag and drop the file here</h4>

                <div className="or-box d-flex">
                  <hr />
                  <p className="mb-3">Or</p>
                  <hr />
                </div>
                <input
                  type="file"
                  id="selectFile"
                  style={{ display: "none" }}
                />
                <div className="row">
                  <button className="selectBtn mt-2">
                    Select from your device
                  </button>
                </div>
              </div>
            </div>
          </section>
          {/* )}
        </Dropzone> */}
        </div>
      )}
    </Modal>
  );
}

export default UploadModal;
