import React from "react";
import Search from "../../mobile/components/Search/Search";
import "./leftview.css";
import { useGlobalContext } from "./../../context/index";
import paper from "../../assets/paper.png";
import person from "../../assets/person.png";
import search from "./search.svg";
import { Link } from "react-router-dom";

export const LandingCards = ({
  cardContainer,
  cardImg,
  cardDesc,
  justifyContent,
  cardClass,
  openModal,
  handleCallActionDisplay,
}) => {
  const { mobileScreen } = useGlobalContext();

  return (
    <div
      className={`row mt-4 ${justifyContent}`}
      style={
        mobileScreen
          ? {
              marginLeft: "2%",
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
            }
          : { marginLeft: "1%" }
      }
    >
      <div className={`card ${cardClass} border-0 mx-2`}>
        <div className="text-dark text-decoration-none" onClick={openModal}>
          <div className="card-img my-2 d-flex justify-content-center">
            <img src={paper} alt="" className="img-fluid" />
          </div>
          <div className="card-body d-flex justify-content-center">
            <h6 className="card-text text-center">Upload the Prescription</h6>
          </div>
        </div>
      </div>
      <div className={`card ${cardClass} border-0 mx-2`}>
        <div
          className="text-dark text-decoration-none"
          onClick={handleCallActionDisplay}
        >
          <div className="card-img my-2 d-flex justify-content-center ">
            <img src={person} alt="" className="img-fluid" />
          </div>
          <div className="card-body d-flex justify-content-center">
            <h6 className="card-text text-center"> Call the pharmastic</h6>
          </div>
        </div>
      </div>
      <div className={`card ${cardClass} border-0 mx-2`}>
        <Link to="/medication" className="text-dark text-decoration-none">
          <div className="card-img my-2 d-flex justify-content-center ">
            <img src={paper} alt="" className="img-fluid" />
          </div>
          <div className="card-body d-flex justify-content-center">
            <h6 className="card-text text-center"> View all Medications</h6>
          </div>
        </Link>
      </div>
      {/* <div
        className={`col-md-4 ${
          cardContainer ? cardContainer : "card-container"
        }`}
      >
        <img
          alt="box"
          className={` ${cardImg ? cardImg : "box-img m-auto"}`}
          src={paper}
        />
        <p className={` ${cardDesc ? cardDesc : "box-desc"}`}>
          Upload the Prescription
        </p>
      </div>
      <div
        className={`col-md-4 ${
          cardContainer ? cardContainer : "card-container"
        }`}
      >
        <img
          alt="box"
          className={` ${cardImg ? cardImg : "box-img m-auto"}`}
          src={person}
        />
        <p className={` ${cardDesc ? cardDesc : "box-desc"}`}>
          Call the pharmastic
        </p>
      </div>
      <div
        className={`col-md-4 ${
          cardContainer ? cardContainer : "card-container"
        }`}
      >
        <img
          alt="box"
          className={` ${cardImg ? cardImg : "box-img m-auto"}`}
          src="https://static.overlay-tech.com/assets/99486dba-4b4f-4ae2-a418-1935165026ca.png"
        />
        <p className={` ${cardDesc ? cardDesc : "box-desc"}`}>
          View all Medications
        </p>
      </div> */}
    </div>
  );
};

function LeftView({ openModal, handleCallActionDisplay }) {
  const { mobileScreen } = useGlobalContext();
  return (
    <div className="container">
      <div className="left-container mt-5">
        <div className="home-headings pt-5">
          <h1 className="home-title">No price games.</h1>
          <h1 className="home-title">Huge drug savings.</h1>
        </div>
        {/* {mobileScreen ? (
        <Search
          containerClass={"search-home"}
          label={"Search by topic"}
          searchClass="search-img"
        />
      ) : (
       
      )} */}
        <div className="search-input position-relative">
          <img src={search} className="img-fluid search-icon" alt="" />
          <input
            className="form-control search"
            placeholder="Search. We have everything"
          />
        </div>
        <LandingCards
          cardClass="col-5 col-md-3"
          openModal={openModal}
          handleCallActionDisplay={handleCallActionDisplay}
        />
      </div>
    </div>
  );
}

export default LeftView;
