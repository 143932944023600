import React from "react";
import "./product.css";
import { Link } from "react-router-dom";

const Product = ({ price, category, mrp, name, productImg }) => {
  return (
    <>
      <Link
        className="product card"
        style={{ cursor: "pointer", textDecoration: "none" }}
        to="/product"
      >
        <div className="card-img">
          <img
            alt="product"
            className={"product-img img-fluid"}
            src={productImg}
          />
        </div>
        <div className="card-body">
          <p className={"optimumNutritionOnGoldStandard100"}>{name}</p>
          <div className={"flex-wrapper"}>
            <p className={"mrp2999"}>
              MRP <span style={{ textDecoration: "line-through" }}>{mrp}</span>
            </p>
          </div>
          <div className="flex-wrapper-2">
            <p className={"num50Off"}>50% off</p>
          </div>
          <p className={"num1499"}>{price}</p>
          <Link
            className="add-to-cart mb-5"
            to="/checkout"
            style={{ textDecoration: "none", color: "black" }}
          >
            {/* <p className="add-to-cart-text m-auto text-center mt-2">
            Add to Cart
          </p> */}
            <div className="d-grid">
              <button className="btn btn-outline-dark btn-lg addToCart-btn">
                Add to Cart
              </button>
            </div>
          </Link>
        </div>
      </Link>
    </>
  );
};

export default Product;

// <div>
//         <Link
//           to="/"
//           id="product"
//           className="card"
//           style={{ cursor: "pointer", textDecoration: "none" }}
//         >
//           <div className="card-img-top">
//             <img
//               className={"product-img img-fluid"}
//               src={productImg}
//               alt="product"
//             />
//           </div>
//           <div className="cart-body mx-3 text-dark mt-1 mb-4">
//             <h5>{name}</h5>
//             <div>
//               <span> MRP</span>
//               <span style={{ textDecoration: "line-through" }}>{mrp}</span>
//               <span>50% Off</span>
//               {/* <span>50% Off</span> */}
//             </div>
//             <p>{price}</p>
//             <Link
//               className="add-to-cart"
//               to="/checkout"
//               style={{ textDecoration: "none" }}
//             >
//               <button className="btn btn-outline-dark btn-lg">
//                 Add to Cart
//               </button>
//             </Link>
//           </div>
//         </Link>
//       </div>
