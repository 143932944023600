import React from "react";
import { Link } from "react-router-dom";
import "./nav.css";

const Nav = () => {
  return (
    <div className={"nav"}>
      <div className={"group1"}>
        <Link to="/" style={{ cursor: "pointer", textDecoration: "none" }}>
          <img
            alt="logo"
            className={"screenshot20220403At12501"}
            src="https://static.overlay-tech.com/assets/90c73d71-5276-48ad-9626-3f370306a87f.png"
          />
        </Link>
      </div>
      <div className={"frame17 mt-3"}>
        <Link
          to="/compare"
          style={{ cursor: "pointer", textDecoration: "none" }}
        >
          <p className={"compareMedicines"}>Compare medicines</p>
        </Link>
        <Link
          to="/medication"
          style={{ cursor: "pointer", textDecoration: "none" }}
        >
          <p className={"compareMedicines"}>All Medications</p>
        </Link>
        <Link
          to="/category"
          style={{ cursor: "pointer", textDecoration: "none" }}
        >
          <p className={"compareMedicines"}>Categories</p>
        </Link>
        <p className={"compareMedicines"}>Blogs</p>
        {/* <Link to='/product' style={{cursor:'pointer', textDecoration:'none'}}>
        <p className={'compareMedicines'}>Product</p>
        </Link>
        <Link to='/checkout' style={{cursor:'pointer', textDecoration:'none'}}>
        <p className={'compareMedicines'}>Checkout</p>
       </Link> */}
      </div>
      <Link
        to="/auth"
        style={{ cursor: "pointer", textDecoration: "none" }}
        className="ms-auto sign-in-box"
      >
        {/* <p className="text-center mt-2 text-white">Sign in</p> */}
        <button className="btn btn-dark sign-in-btn">Sign In</button>
      </Link>
    </div>
  );
};

export default Nav;
