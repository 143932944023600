import bucket from "../../../assets/bucket.png";
import purewhey from "../../../assets/purewhey.png";
import vitamin from "../../../assets/vitamin.png";
import whey from "../../../assets/whey.png";

const productData = [
  {
    name: "Optimum Nutrition (ON) Gold Standard 100% Whey Protein",
    category: "Jar of 2lb powder",
    price: "₹1499",
    mrp: "₹2999",
    src: whey,
  },
  {
    name: "Optimum Nutrition (ON) Gold Standard 100% Whey Protein",
    category: "Jar of 2lb powder",
    price: "₹1499",
    mrp: "₹2999",
    src: bucket,
  },
  {
    name: "Optimum Nutrition (ON) Gold Standard 100% Whey Protein",
    category: "Jar of 2lb powder",
    price: "₹1499",
    mrp: "₹2999",
    src: whey,
  },
  {
    name: "Optimum Nutrition (ON) Gold Standard 100% Whey Protein",
    category: "Jar of 2lb powder",
    price: "₹1499",
    mrp: "₹2999",
    src: vitamin,
  },
  {
    name: "Optimum Nutrition (ON) Gold Standard 100% Whey Protein",
    category: "Jar of 2lb powder",
    price: "₹1499",
    mrp: "₹2999",
    src: whey,
  },
  {
    name: "Optimum Nutrition (ON) Gold Standard 100% Whey Protein",
    category: "Jar of 2lb powder",
    price: "₹1499",
    mrp: "₹2999",
    src: vitamin,
  },
  {
    name: "Optimum Nutrition (ON) Gold Standard 100% Whey Protein",
    category: "Jar of 2lb powder",
    price: "₹1499",
    mrp: "₹2999",
    src: purewhey,
  },
  {
    name: "Optimum Nutrition (ON) Gold Standard 100% Whey Protein",
    category: "Jar of 2lb powder",
    price: "₹1499",
    mrp: "₹2999",
    src: vitamin,
  },
];

export default productData;
