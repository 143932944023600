import React from 'react'
import Supplements from '../../layouts/Supplements/Supplements';


function SupplementsView() {
  return (
    <div>
        <Supplements />
    </div>
  )
}

export default SupplementsView;