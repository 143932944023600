import React, { useState } from "react";
import Modal from "react-modal";
import UploadSuccessImg from "./microsoft-word.svg";

export default function UploadSuccess({ images, files, setModalOpen }) {
  const customStyles = {
    content: {
      top: "20%",
      backgroundColor: "rgba(255, 255, 255, 1)",
      borderRadius: 10,
    },
  };

  const [uploadSuccess, setUploadSuccess] = useState(true);

  const closeModal = () => {
    setUploadSuccess(false);
    setModalOpen(false);
  };

  const fileName = [];

  //   console.log(images.length === 0 ? "0" : "1");
  console.log(images);
  //   if (images) {
  // console.log(images[0].name);
  //   }
  //   console.log(f);
  return (
    <Modal
      style={customStyles}
      className="card col-10 col-md-8 col-lg-6 m-auto"
      //   isOpen={uploadSuccess}
      isOpen={uploadSuccess}
      ariaHideApp={false}
      onRequestClose={closeModal}
      shouldFocusAfterRender={true}
    >
      <div className="upload-container">
        <div className="card-title d-flex align-items-center">
          <img
            alt=""
            className="img-fluid"
            src="https://static.overlay-tech.com/assets/cd7031b1-7ff9-4aa7-bacb-145c2ead5a5d.png"
          />
          <h4 className="ms-2"> Prescription Uploaded</h4>
        </div>
        <div className="card-body upload-success-body d-flex align-items-center">
          <img
            src={UploadSuccessImg}
            alt=""
            className="img-fluid w-25"
            // style={{ width: "50px" }}
          />
          <div className="upload-success-text mx-2">
            <h6>{images === undefined ? " " : images[0].name}</h6>
            <p>
              Resceived your Prescription, we will add all the medicines to your
              cart soon and call you.
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
}
