import vitamin from '../../assets/vitamin.png';
import whey from '../../assets/whey.png';

const productDatacheck = [
    {
        name: 'Optimum Nutrition (ON) Gold Standard 100% Whey Protein',
        category:'Jar of 2lb powder',
        price: '₹1499',
        mrp: '₹2999',
        src:whey
    },
    {
        name: 'Optimum Nutrition (ON) Gold Standard 100% Whey Protein',
        category:'Jar of 2lb powder',
        price: '₹1499',
        mrp: '₹2999',
        src:vitamin
    },
    {
        name: 'Optimum Nutrition (ON) Gold Standard 100% Whey Protein',
        category:'Jar of 2lb powder',
        price: '₹1499',
        mrp: '₹2999',
        src:whey
    }
]

export default productDatacheck;