export const data = [
    {
        name: 'Imatinib',
        price: '₹34.99', 
        comma: '(Generic for Gleevec)',
        retailPrice: 'Retail price ₹234.45'
    },
    {
        name: 'Imatinib',
        price: '₹34.99', 
        comma: '(Generic for Gleevec)',
        retailPrice: 'Retail price ₹234.45'
    },
    {
        name: 'Imatinib',
        price: '₹34.99', 
        comma: '(Generic for Gleevec)',
        retailPrice: 'Retail price ₹234.45'
    },
    {
        name: 'Imatinib',
        price: '₹34.99', 
        comma: '(Generic for Gleevec)',
        retailPrice: 'Retail price ₹234.45'
    },
    {
        name: 'Imatinib',
        price: '₹34.99', 
        comma: '(Generic for Gleevec)',
        retailPrice: 'Retail price ₹234.45'
    },
    {
        name: 'Imatinib',
        price: '₹34.99', 
        comma: '(Generic for Gleevec)',
        retailPrice: 'Retail price ₹234.45'
    },
    {
        name: 'Imatinib',
        price: '₹34.99', 
        comma: '(Generic for Gleevec)',
        retailPrice: 'Retail price ₹234.45'
    },
    {
        name: 'Imatinib',
        price: '₹34.99', 
        comma: '(Generic for Gleevec)',
        retailPrice: 'Retail price ₹234.45'
    },
 {
        name: 'Imatinib',
        price: '₹34.99', 
        comma: '(Generic for Gleevec)',
        retailPrice: 'Retail price ₹234.45'
    }
]

export const mobileData = [
    {
        name: 'Imatinib',
        price: '₹34.99', 
        comma: '(Generic for Gleevec)',
        retailPrice: 'Retail price ₹234.45'
    },
    {
        name: 'Imatinib',
        price: '₹34.99', 
        comma: '(Generic for Gleevec)',
        retailPrice: 'Retail price ₹234.45'
    },
    {
        name: 'Imatinib',
        price: '₹34.99', 
        comma: '(Generic for Gleevec)',
        retailPrice: 'Retail price ₹234.45'
    },
    {
        name: 'Imatinib',
        price: '₹34.99', 
        comma: '(Generic for Gleevec)',
        retailPrice: 'Retail price ₹234.45'
    },
    {
        name: 'Imatinib',
        price: '₹34.99', 
        comma: '(Generic for Gleevec)',
        retailPrice: 'Retail price ₹234.45'
    },
    {
        name: 'Imatinib',
        price: '₹34.99', 
        comma: '(Generic for Gleevec)',
        retailPrice: 'Retail price ₹234.45'
    }
]