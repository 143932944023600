import React from "react";
import { LandingCards } from "./LeftView";
import search from "./search.svg";
import "./cta.css";
import { useGlobalContext } from "../../context";
import Search from "../../mobile/components/Search/Search";

function CTA() {
  const { mobileScreen } = useGlobalContext();
  return (
    <div className="cta-container container">
      <h1 className="cta-title">
        You are just a search away from your medicine
      </h1>

      <div className="my-5 position-relative">
        <input
          type="text"
          className="form-control rounded-pill py-3 ps-5 input-search"
          placeholder={
            mobileScreen ? "Search by topic" : "Search. We have everything"
          }
        />
        <img src={search} alt="" className="search-img" />
      </div>

      <div
        // className="row"
        style={
          mobileScreen
            ? {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-3%",
              }
            : {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-3%",
                // marginLeft: "-100px",
              }
        }
      >
        <LandingCards
          cardContainer={"cta-card"}
          cardImg={"cta-card-img"}
          cardDesc={"cta-card-desc"}
          justifyContent={"justify-content-center"}
          cardClass="col-10 col-md-3"
        />
      </div>
    </div>
  );
}

export default CTA;
