import React, { useState } from "react";
import { useGlobalContext } from "../../context";
import About from "../../layouts/Home/About";
import CelebNote from "../../layouts/Home/CelebNote";
import CTA from "../../layouts/Home/CTA";
import LeftHome from "../../layouts/Home/LeftView";
import ProductLayout from "../../layouts/Home/Product/Product-layout";
import RespFooter from "../../mobile/Layout/RespFooter/RespFooter";
import MainNav from "../../mobile/Layout/MobileNav/MainNav";

import Nav from "./../../layouts/Nav/Nav";
import Footer from "../../layouts/Footer/Footer";
import RightHome from "../../layouts/Home/RightHome";
import UploadModal from "../../components/uploadModal/UploadModal";
import CallAction from "../../components/CallAction/CallAction";

function Home() {
  const { mobileScreen } = useGlobalContext();

  // Upload Prescription
  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => setModalOpen(false);

  // Call the Pharmastic
  const [callActionDisplay, setCallActionDisplay] = useState(false);
  const handleCallActionDisplay = () => {
    setCallActionDisplay(true);
    console.log("Hey ANKIT");
  };
  const closeCallAction = () => {
    setCallActionDisplay(false);
  };

  return (
    <div style={{ backgroundColor: "#FDFAF6" }}>
      <div className="container-fluid">
        <div style={{ height: "100vh" }}>
          {mobileScreen ? <MainNav /> : <Nav />}
          <div className="header-section">
            <LeftHome
              openModal={openModal}
              handleCallActionDisplay={handleCallActionDisplay}
            />
            {mobileScreen ? null : <RightHome />}
          </div>
        </div>
        <UploadModal
          modalIsOpen={modalOpen}
          setOpen={setModalOpen}
          closeModal={closeModal}
          setModalOpen={setModalOpen}
        />
        <CallAction
          callActionDisplay={callActionDisplay}
          closeCallAction={closeCallAction}
        />

        <CelebNote />
        <ProductLayout />
        <About />
        <CTA />
        {mobileScreen ? <RespFooter /> : <Footer />}
      </div>
    </div>
  );
}

export default Home;
